import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/text.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Text: Text }}
        code={snippet}
        platform="react"
        gitHubLink="type/text"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="level" types={['oneOf', 'string']}>
            <Text>The level (size) of the text.</Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>micro</code>
              </li>
              <li>
                <code>large</code>
              </li>
              <li>
                <code>additional</code> (deprecated - use{' '}
                <code>color="nonessential"</code> instead)
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isTight" types={['bool']}>
            <Text>
              Determines whether to show the text with tighter line-height.
            </Text>
          </PropListItem>

          <PropListItem name="color" types={['oneOf', 'string']}>
            <Text>Determines the color of the text.</Text>
            <List type="unordered">
              <li>
                <code>contrast</code>
              </li>
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>subtle</code>
              </li>
              <li>
                <code>nonessential</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="hideOverflow" types={['bool']}>
            <Text>
              Determines whether to truncate the text with an ellipsis.
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this text in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-helpers/space?web">
                uniCSS space classes
              </Link>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
